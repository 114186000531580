import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import MobileMenu from "./MobileMenu";
import Button from "./Button";
import WhatsAppButton from "./WhatsAppButton";
import "./Navbar.css";

const Navbar = ({ isMobile }) => {
  const navigate = useNavigate();
  const [isPhoneVisible, setIsPhoneVisible] = useState(
    window.innerWidth > 1000
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsPhoneVisible(window.innerWidth > 1000);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      let found = false;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!found) {
            setActiveSection(entry.target.id);
            window.history.replaceState(null, "", `#${entry.target.id}`);
            found = true;
          }
        }
      });
    }, options);

    const sections = document.querySelectorAll("div[id]");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleContactButton = () => {
    navigate("/#appointment");
  };

  const routes = [
    { path: "#intro", label: "Start" },
    { path: "#workshop", label: "Werkstatt" },
    { path: "#team", label: "Team" },
    { path: "#project", label: "Projekte" },
    { path: "#appointment", label: "Termine" },
    { path: "#location", label: "Anfahrt" },
  ];

  const handleSmoothScroll = (event, path) => {
    event.preventDefault();
    const targetId = path.slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (event, path) => {
    if (isMobile) {
      event.preventDefault();
      navigate("/" + path);
    } else {
      handleSmoothScroll(event, path);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <img
        src="./logo-v2.png"
        alt="Logo"
        className="logo"
        onClick={scrollToTop}
        style={{ cursor: "pointer" }} // Optional: to indicate the logo is clickable
      />
      <div className="nav-grid">
        <div className="menu-grid">
          {routes.map((route) => (
            <a
              key={route.path}
              href={route.path}
              className={activeSection === route.path.slice(1) ? "active" : ""}
              onClick={(e) => handleClick(e, route.path)}
            >
              {route.label}
            </a>
          ))}
        </div>
      </div>
      <MobileMenu routes={routes} />
      {isPhoneVisible && (
        <div>
          <div className="phone-grid">
            <>
              <WhatsAppButton phoneNumber={"+4915756850714"} pulsate />

              <div>
                <span className="phone-tag">
                  Persönliche Beratung
                  <br />
                </span>
                <a href="tel:+4915756850714" className="phone-number">
                  +49 157 568 50 714
                </a>
              </div>
            </>
          </div>
          <div className="contact-button">
            <Button type="primary" onClick={handleContactButton}>
              Kontakt
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
