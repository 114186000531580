import React, { useState, useRef, useEffect } from "react";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WorkshopGallery } from "../pages/WorkshopGallery";
import "./Concept.css"; // for styling
import PulsatingArrow from "./PulsatingArrow"; // Import the new component
import ImageGrid from "./ImageGrid";

const services = [
  "Service + Inspektion",
  "Restauration",
  "Revision Motor + Getriebe",
  "Leistungssteigerungen",
  "Umbauten",
  "Sonderanfertigungen",
  "Optimierung von Teilen",
  "Fahrwerksoptimierung",
];

const details = [
  {
    text: "Unsere Autowerkstatt bietet umfassende Service- und Inspektionsleistungen für klassische Porsche-Modelle. Mit über 30 Jahren Erfahrung im Motorsport und in der Fahrzeugtechnik garantiert unser Werkstattmeister höchste Qualität. Zu unseren typischen Arbeiten gehören Ölwechsel, Bremsenprüfung, Elektronikchecks, Zündkerzenwechsel, Reifenservice und die Überprüfung aller sicherheitsrelevanten Bauteile. Regelmäßige Inspektionen sichern den Werterhalt und die Zuverlässigkeit Ihres Fahrzeugs. Vertrauen Sie auf unsere Expertise für eine gründliche und professionelle Wartung, die speziell auf die Bedürfnisse klassischer Porsche-Modelle zugeschnitten ist.",
    image: "./service.jpg",
  },
  {
    text: "Die Restauration klassischer Porsche-Modelle ist eine unserer Kernkompetenzen. Unser erfahrenes Team sorgt dafür, dass Ihr Fahrzeug in seinem ursprünglichen Glanz erstrahlt. Zu den typischen Arbeiten gehören die komplette Zerlegung des Fahrzeugs, Karosseriearbeiten, Lackierungen, Erneuerung der Innenausstattung und die Instandsetzung aller technischen Komponenten. Wir verwenden ausschließlich Originalteile oder qualitativ hochwertige Reproduktionen, um die Authentizität zu bewahren. Lassen Sie Ihr klassisches Porsche-Modell von uns restaurieren und erleben Sie den Charme vergangener Zeiten in neuem Glanz.",
    image: "./restauration.jpg",
  },
  {
    text: "Die Revision von Motor und Getriebe ist eine unserer Spezialitäten. Unser Team zerlegt und überprüft jede Komponente, um Verschleißteile zu ersetzen und die Leistung zu optimieren. Typische Arbeiten umfassen den Austausch von Kolben, Lagern, Dichtungen und Zahnrädern sowie die Feinabstimmung der Komponenten. Mit unserer langjährigen Erfahrung und unserem technischen Know-how sorgen wir dafür, dass Ihr Motor und Getriebe wieder in Bestform sind und die ursprüngliche Leistung Ihres Porsche wiederhergestellt wird.",
    image: "./restauration.jpg",
  },
  {
    text: "Wir bieten professionelle Leistungssteigerungen für klassische Porsche-Modelle, um das volle Potenzial Ihres Fahrzeugs auszuschöpfen. Typische Arbeiten umfassen die Optimierung des Motormanagements, den Einbau von Turboladern, Sportauspuffanlagen und Hochleistungsbremsen. Unsere Experten passen die Leistungskomponenten individuell an Ihr Fahrzeug an, um eine perfekte Balance zwischen Leistung und Zuverlässigkeit zu gewährleisten. Vertrauen Sie auf unsere Motorsport-Erfahrung, um die Leistung Ihres Porsche auf ein neues Niveau zu heben.",
    image: "./restauration.jpg",
  },
  {
    text: "Wir bieten maßgeschneiderte Umbauten für klassische Porsche-Modelle, um Ihre individuellen Vorstellungen zu realisieren. Ob Modernisierung der Elektronik, Einbau neuer Sitze, Anpassung des Armaturenbretts oder Integration moderner Fahrassistenzsysteme – wir setzen Ihre Wünsche um. Typische Arbeiten umfassen auch den Austausch der Auspuffanlage, die Installation eines neuen Soundsystems und die Anpassung der Beleuchtung. Unser erfahrenes Team sorgt dafür, dass alle Umbauten fachgerecht und stilgerecht durchgeführt werden, damit Ihr Porsche einzigartig wird.",
    image: "./restauration.jpg",
  },
  {
    text: "Unsere Werkstatt ist spezialisiert auf Sonderanfertigungen für klassische Porsche-Modelle. Wir realisieren einzigartige Projekte nach Ihren Vorstellungen, sei es ein maßgeschneiderter Innenraum, spezielle Lackierungen oder individuelle Karosserieteile. Typische Arbeiten umfassen die Anfertigung von Einzelstücken, spezielle Lederarbeiten, Anpassungen an der Karosserie und Sonderlackierungen. Mit unserer langjährigen Erfahrung und Leidenschaft für Details setzen wir Ihre Visionen präzise um und machen Ihr Fahrzeug zu einem Unikat.",
    image: "./restauration.jpg",
  },
  {
    text: "Für die Optimierung von Teilen an klassischen Porsche-Modellen sind Sie bei uns genau richtig. Wir verbessern die Leistung und Lebensdauer durch gezielte Optimierungen. Typische Arbeiten umfassen die Anpassung und Verbesserung von Bremssystemen, Fahrwerken, Auspuffanlagen und Motorenteilen. Unser Ziel ist es, durch den Einsatz hochwertiger Materialien und modernster Techniken die Leistung und Effizienz Ihres Fahrzeugs zu steigern. Vertrauen Sie auf unser Know-how um das Beste aus Ihrem Porsche herauszuholen.",
    image: "./restauration.jpg",
  },

  {
    text: "Eine Fahrwerksoptimierung verbessert das Handling und die Straßenlage Ihres klassischen Porsche. Typische Arbeiten umfassen den Einbau von Sportfahrwerken, die Anpassung der Federung und Dämpfung sowie die Feinabstimmung des Fahrwerks auf Ihre individuellen Bedürfnisse. Wir verwenden hochwertige Komponenten und modernste Techniken, um die Fahrdynamik und den Fahrkomfort zu maximieren. Lassen Sie Ihr Fahrwerk von unseren Experten optimieren und erleben Sie ein neues Fahrgefühl in Ihrem klassischen Porsche.",
    image: "./restauration.jpg",
  },
];

export const Concept = () => {
  const containerRef = useRef(null);

  const [expandedIndices, setExpandedIndices] = useState([]);
  const contentRefs = useRef([]);

  const [heights, setHeights] = useState({});

  const handleToggle = (index) => {
    setExpandedIndices((prevIndices) => {
      const isExpanded = prevIndices.includes(index);
      const newIndices = isExpanded
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index];

      // Scroll the clicked item into view with a 20px offset
      if (contentRefs.current[index]) {
        const element = contentRefs.current[index].parentNode;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 170;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }

      return newIndices;
    });
  };

  const toggleAll = () => {
    if (expandedIndices.length == 0) {
      setExpandedIndices(services.map((_, index) => index));
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setExpandedIndices([]);
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: "auto" });
      }
    }
  };

  useEffect(() => {
    const newHeights = {};
    expandedIndices.forEach((index) => {
      if (contentRefs.current[index]) {
        newHeights[index] = contentRefs.current[index].scrollHeight;
      }
    });
    setHeights(newHeights);
  }, [expandedIndices]);

  return (
    <div className="container" style={{ paddingTop: 0 }}>
      <div style={{ color: "white", padding: "40px 0" }} className="bg">
        <div
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            paddingTop: 40,
            paddingBottom: 80,
          }}
        >
          <div className="p-20">
            <h1 className="headline-blue">Unser Konzept</h1>
            <h2>Was WTM Sportwagen auszeichnet</h2>
            <p>
              Herzlich Willkommen bei WTM-Sportwagen,{" "}
              <strong>
                Ihrer KFZ-Meisterwerkstatt für Porsche Oldtimer und Youngtimer
              </strong>{" "}
              in Gießen.
            </p>
            <p>
              Wir - das sind Thomas, Marcel und Andreas - haben uns durch die
              Leidenschaft für Porsche und Oldtimer kennengelernt und
              beschlossen einen gemeinsamen Weg zu gehen.
            </p>
          </div>
          <div className="text-columns-container">
            <div className="text-column">
              <div
                className="column-image-wrapper"
                style={{ position: "relative", margin: "0 20px" }}
              >
                <img
                  src="./tab.png"
                  alt="Know How"
                  className="column-image"
                  style={{
                    width: "100%",
                    border: "2px solid lightyellow",
                  }}
                />
                <div
                  className="headline"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "98%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "lightyellow",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  QUALITÄT
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#2E4049",
                  color: "lightyellow",
                  padding: 20,
                  margin: "-5px 20px 20px 20px",
                  minHeight: 420,
                  border: "2px solid lightyellow",
                  borderTop: "none",
                }}
              >
                <h3>Qualität</h3>
                <p>
                  Wir lieben die Arbeit an diesen faszinierenden Fahrzeugen und
                  sind stolz darauf sie auf höchstem Niveau mit Service zu
                  versorgen und zu restaurieren.
                </p>
                <p>
                  Wir arbeiten absolut transparent und stehen zu der Aussage -
                  uns kann man Vertrauen.
                </p>
              </div>
            </div>
            <div className="text-column">
              <div
                className="column-image-wrapper"
                style={{ position: "relative", margin: "0 20px" }}
              >
                <img
                  src="./tab.png"
                  alt="Know How"
                  className="column-image"
                  style={{
                    width: "100%",
                    border: "2px solid lightyellow",
                  }}
                />
                <div
                  className="headline"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "98%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "lightyellow",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  TRANSPARENZ
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#2E4049",
                  color: "lightyellow",
                  padding: 20,
                  margin: "-5px 20px 20px 20px",
                  minHeight: 420,
                  border: "2px solid lightyellow",
                  borderTop: "none",
                }}
              >
                <h3>Transparenz</h3>
                <p>
                  Unser Team aus erfahrenen Fachleuten hat tiefgreifende
                  Kenntnisse über Technik, Historie und über 30 Jahre Erfahrung
                  bei der Porsche Restaurierung, Reparatur und Service.
                </p>
                <p>
                  Unser Anspruch ist es exzellente Arbeit zu leisten und
                  Fahrzeuge auf höchstem Niveau zu versorgen.
                </p>
              </div>
            </div>
            <div className="text-column">
              <div
                className="column-image-wrapper"
                style={{ position: "relative", margin: "0 20px" }}
              >
                <img
                  src="./tab.png"
                  alt="Know How"
                  className="column-image"
                  style={{
                    width: "100%",
                    border: "2px solid lightyellow",
                  }}
                />
                <div
                  className="headline"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "98%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "lightyellow",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  VERTRAUEN
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#2E4049",
                  color: "lightyellow",
                  padding: 20,
                  margin: "-5px 20px 20px 20px",
                  minHeight: 420,
                  border: "2px solid lightyellow",
                  borderTop: "none",
                }}
              >
                <h3>Vertrauen</h3>
                <p>
                  Oldtimer Restaurierung ist unser Fachgebiet. Wir nehmen uns
                  die Zeit alte Teile zu restaurieren da der Markt oft keine
                  Alternativen bietet.
                </p>
                <p>
                  Wir kombinieren alte Technik mit neuen Möglichkeiten um
                  Technik und Optik zu einem perfekten Gesamtbild zu vereinen
                  und ein Einzelstück zu schaffen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="workshop"></div>
      <WorkshopGallery />
      <div className="container two-column-container" style={{ marginTop: 0 }}>
        <div className="grid-container">
          <div className="left-column">
            <div style={{ backgroundColor: "#2E4049" }}>
              <div style={{ maxWidth: 1200, margin: "0 auto", paddingTop: 80 }}>
                <div className="column p-20">
                  <h2>Leistungsübersicht</h2>
                  <p>{/* Content of the paragraph */}</p>
                  <div ref={containerRef}></div>
                  <div className="flexer" style={{ marginTop: 60 }}>
                    {services.map((service, index) => (
                      <div
                        key={index}
                        className={`expandable-wrapper ${
                          expandedIndices.includes(index) ? "expanded" : ""
                        }`}
                        onClick={() => handleToggle(index)}
                        style={{
                          overflow: "hidden",
                          transition: "height 0.3s ease",
                          height: expandedIndices.includes(index)
                            ? `${heights[index] + 85 || 0}px`
                            : "65px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: 10,
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={faCheck}
                              color="green"
                              size="lg"
                              className="check-icon"
                            />
                            {service}
                          </div>
                          <FontAwesomeIcon
                            icon={
                              expandedIndices.includes(index)
                                ? faChevronUp
                                : faChevronDown
                            }
                            size="lg"
                          />
                        </div>
                        <div
                          ref={(el) => (contentRefs.current[index] = el)}
                          className="expandable-content"
                        >
                          <p>{details[index]?.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ padding: 40 }}>
                <button
                  onClick={toggleAll}
                  style={{
                    display: "block",
                    margin: "20px auto 40px auto",
                    padding: 20,
                    minWidth: 200,
                  }}
                >
                  {expandedIndices.length == 0
                    ? "Mehr Details"
                    : "Zurück zur Übersicht"}
                </button>
              </div>

              <div style={{ maxWidth: 1200, margin: "0 auto", paddingTop: 0 }}>
                <div className="column p-20">
                  <h2>Unsere Schwerpunkte für Ihren Porsche</h2>
                  <div class="schwerpunkt-flexer" style={{ marginBottom: 40 }}>
                    <ImageGrid />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-column">
            {/* Content of the right column */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concept;
