import React from "react";
import "./Avatars.css";

const Avatars = () => {
  const avatars = [
    {
      imgSrc: "./avatar-thomas.png", // replace with actual image paths
      title: "Thomas Thiesen",
      position: "Werkstattleiter",
      description:
        "KFZ - Meister seit 1994, mit 30 Jahren Porsche Erfahrung. Spezialgebiet: Luftgekühlte 911er Motoren, Reparatur und Leistungssteigerung. Nach der Meisterschule arbeitete er bei der Firma SHK und lernte von Harry Weishaupt und Klaus Dörrbecker. Stärken: Geduld und Liebe zum Detail, um optisch und technisch Perfektion zu erreichen.",
      styles: "#3A0C0B",
    },
    {
      imgSrc: "./avatar-marcel.png", // replace with actual image paths
      title: "Marcel Herder",
      description:
        "Marcel ist unser Fachmann wenn es um die Baureihen ab 996 geht. Mit über 10 Jahren Porsche Erfahrung konnte er im Motorsport mit 996 Cup und 997 Cup umfangreiche Erfahrung sammeln. Zusätzlich ist die Elektronik sein Hobby und die Fehlerbehebung seine Mission. Bei der wassergekühlten Fraktion ist er in seinem Element und steht euch gerne mit Rat und Tat zur Seite.",

      styles: "#3A0C0B",
    },
    {
      imgSrc: "./avatar-andreas.png", // replace with actual image paths
      title: "Andreas Wutschke",
      description:
        "Andreas ist zuständig für die Beratung rund um Ankauf und Verkauf.",
      styles: "#3A0C0B",
    },
  ];

  return (
    <div style={{ backgroundColor: "#3A0C0B" }}>
      <div style={{ maxWidth: 1200, margin: "0 auto" }} className="p-20">
        <h2 style={{ paddingTop: 80 }}>Wer wir sind</h2>
        <p>
          Wir - das sind Thomas, Marcel und Andreas - haben uns durch die
          Leidenschaft für Porsche und Oldtimer kennengelernt und beschlossen
          einen gemeinsamen Weg zu gehen.
        </p>
        <div className="avatar-flexbox">
          {avatars.map((avatar, index) => (
            <div
              className="avatar-card"
              key={index}
              style={{ backgroundColor: avatar.styles }}
            >
              <div className="avatar-image-container">
                <img
                  src={avatar.imgSrc}
                  alt={avatar.title}
                  className="avatar-image"
                />
              </div>
              <div className="avatar-details">
                <h3>{avatar.title}</h3>
                <h5>{avatar.position}</h5>
                <p>{avatar.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Avatars;
