import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import "./MobileMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faScrewdriverWrench,
  faBoltLightning,
  faPeopleGroup,
  faCalendarDays,
  faNavicon,
} from "@fortawesome/free-solid-svg-icons";
import WhatsAppButton from "./WhatsAppButton";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  if (!isMobile) return null;
  return (
    <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
      {isMobile && (
        <div className="menu-icon" onClick={toggleMenu}>
          <FiMenu className={`menu-icon ${isOpen ? "open" : ""}`} />
        </div>
      )}

      <div className="menu-links full-height">
        <div className="menu-links-inner">
          <Link to="/#workshop" onClick={toggleMenu}>
            <FontAwesomeIcon
              icon={faScrewdriverWrench}
              color="white"
              size="sm"
            />
            <span className="spacer-left-5">Werkstatt</span>
          </Link>
          <Link to="/#team" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faPeopleGroup} color="white" size="sm" />
            <span className="spacer-left-5">Team</span>
          </Link>
          <Link to="/#project" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBoltLightning} color="white" size="sm" />
            <span className="spacer-left-5">Projekte</span>
          </Link>
          <Link to="/#appointment" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faCalendarDays} color="white" size="sm" />
            <span className="spacer-left-5">Termine</span>
          </Link>
          <Link to="/#location" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faNavicon} color="white" size="sm" />
            <span className="spacer-left-5">Anfahrt</span>
          </Link>
          <div className="phone-grid-2">
            <WhatsAppButton phoneNumber={"+4915756850714"} />
            <div>
              <span className="phone-tag-2">
                Persönliche Beratung
                <br />
              </span>
              <span className="phone-number-2">+49 157 568 50 714</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
