import React, { useEffect } from "react";
import "./PrivacyPolicy.css";
import Navbar from "../components/Navbar";

const PrivacyPolicy = () => {
  // Use useEffect to scroll to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar isMobile={true} />
      <div className="privacy-policy">
        <h1 style={{ color: "white" }}>Datenschutzerklärung</h1>

        <p>
          Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des
          Datenschutzrechts (insb. gemäß BDSG n.F. und der europäischen
          Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und
          Zweck der Verarbeitung personenbezogener Daten durch unser
          Unternehmen. Diese Datenschutzerklärung gilt auch für unsere Websites
          und Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie
          etwa „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf
          Art. 4 DS-GVO.
        </p>

        <section>
          <h2>Name und Kontaktdaten des / der Verantwortlichen</h2>
          <p>
            Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d.
            Art. 4 Zif. 7 DS-GVO ist:
          </p>
          <address>
            WTM Sportwagen Werkstatt <br />
            Grünbergerstr. 140 <br />
            35394 <br />
            Inhaber Andreas Wutschke <br />
            E-Mail-Adresse: werkstatt@wtm-sportwagen.com
          </address>
        </section>

        <section>
          <h2>
            Datenarten, Zwecke der Verarbeitung und Kategorien betroffener
            Personen
          </h2>

          <h3>1. Arten der Daten, die wir verarbeiten</h3>
          <p>Kommunikationsdaten (IP-Adresse etc.),</p>

          <h3>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</h3>
          <p>
            Abwicklung von Verträgen, Kontaktanfragen abwickeln,
            Unterbrechungsfreier, sicherer Betrieb unserer Website,
          </p>

          <h3>
            3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO
          </h3>
          <p>
            Die betroffenen Personen werden zusammenfassend als „Nutzer“
            bezeichnet.
          </p>
        </section>

        <section>
          <h2>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</h2>
          <p>
            Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der
            Verarbeitung personenbezogener Daten:
          </p>
          <ol>
            <li>
              Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen
              Daten eingeholt haben, ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO
              Rechtsgrundlage.
            </li>
            <li>
              Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur
              Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Ihre
              Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO
              Rechtsgrundlage.
            </li>
            <li>
              Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
              erforderlich, der wir unterliegen (z.B. gesetzliche
              Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO
              Rechtsgrundlage.
            </li>
            <li>
              Ist die Verarbeitung erforderlich, um lebenswichtige Interessen
              der betroffenen Person oder einer anderen natürlichen Person zu
              schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO
              Rechtsgrundlage.
            </li>
            <li>
              Ist die Verarbeitung zur Wahrung unserer oder der berechtigten
              Interessen eines Dritten erforderlich und überwiegen diesbezüglich
              Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist
              Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
            </li>
          </ol>
        </section>

        <section>
          <h2>
            Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter
          </h2>
          <p>
            Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte
            weiter. Sollte dies doch der Fall sein, dann erfolgt die Weitergabe
            auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der
            Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung
            oder aufgrund gerichtlicher Anordnung oder wegen einer gesetzlichen
            Verpflichtung zur Herausgabe der Daten zum Zwecke der
            Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung der Rechte
            am geistigen Eigentum.
          </p>
          <p>
            Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum
            Webhosting unserer Websites und Datenbanken) zur Verarbeitung Ihrer
            Daten ein. Wenn im Rahmen einer Vereinbarung zur
            Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben
            werden, erfolgt dies immer nach Art. 28 DS-GVO. Wir wählen dabei
            unsere Auftragsverarbeiter sorgfältig aus, kontrollieren diese
            regelmäßig und haben uns ein Weisungsrecht hinsichtlich der Daten
            einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete
            technische und organisatorische Maßnahmen getroffen haben und die
            Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten.
          </p>
        </section>

        <section>
          <h2>Datenübermittlung in Drittstaaten</h2>
          <p>
            Durch die Verabschiedung der europäischen
            Datenschutz-Grundverordnung (DS-GVO) wurde eine einheitliche
            Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten
            werden daher vorwiegend durch Unternehmen verarbeitet, für die
            DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch Dienste
            Dritter außerhalb der Europäischen Union oder des Europäischen
            Wirtschaftsraums stattfinden, so müssen diese die besonderen
            Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die
            Verarbeitung erfolgt aufgrund besonderer Garantien, wie etwa die von
            der EU-Kommission offiziell anerkannte Feststellung eines der EU
            entsprechenden Datenschutzniveaus oder der Beachtung offiziell
            anerkannter spezieller vertraglicher Verpflichtungen, der so
            genannten „Standardvertragsklauseln“.
          </p>
          <p>
            Soweit wir aufgrund der Unwirksamkeit des sog. „Privacy Shields“,
            nach Art. 49 Abs. 1 S. 1 lit. a) DSGVO die ausdrückliche
            Einwilligung in die Datenübermittlung in die USA von Ihnen einholen,
            weisen wir diesbezüglich auf das Risiko eines geheimen Zugriffs
            durch US-Behörden und die Nutzung der Daten zu Überwachungszwecken,
            ggf. ohne Rechtsbehelfsmöglichkeiten für EU-Bürger, hin.
          </p>
        </section>

        <section>
          <h2>Löschung von Daten und Speicherdauer</h2>
          <p>
            Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben,
            werden Ihre personenbezogen Daten gelöscht oder gesperrt, sobald die
            zur Verarbeitung erteilte Einwilligung von Ihnen widerrufen wird
            oder der Zweck für die Speicherung entfällt bzw. die Daten für den
            Zweck nicht mehr erforderlich sind, es sei denn deren weitere
            Aufbewahrung ist zu Beweiszwecken erforderlich oder dem stehen
            gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter fallen
            etwa handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen
            nach § 257 Abs. 1 HGB (6 Jahre) sowie steuerrechtliche
            Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre).
            Wenn die vorgeschriebene Aufbewahrungsfrist abläuft, erfolgt eine
            Sperrung oder Löschung Ihrer Daten, es sei denn die Speicherung ist
            weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung
            erforderlich.
          </p>
        </section>

        <section>
          <h2>Bestehen einer automatisierten Entscheidungsfindung</h2>
          <p>
            Wir setzen keine automatische Entscheidungsfindung oder ein
            Profiling ein.
          </p>
        </section>

        <section>
          <h2>Bereitstellung unserer Website und Erstellung von Logfiles</h2>
          <ol>
            <li>
              Wenn Sie unsere Webseite lediglich informatorisch nutzen (also
              keine Registrierung und auch keine anderweitige Übermittlung von
              Informationen), erheben wir nur die personenbezogenen Daten, die
              Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website
              betrachten möchten, erheben wir die folgenden Daten:
              <ul>
                <li>IP-Adresse</li>
                <li>Internet-Service-Provider des Nutzers</li>
                <li>Datum und Uhrzeit des Abrufs</li>
                <li>Browsertyp</li>
                <li>Sprache und Browser-Version</li>
                <li>Inhalt des Abrufs</li>
                <li>Zeitzone</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>Datenmenge</li>
                <li>Websites, von denen die Anforderung kommt</li>
                <li>Betriebssystem</li>
              </ul>
              Eine Speicherung dieser Daten zusammen mit anderen
              personenbezogenen Daten von Ihnen findet nicht statt.
            </li>
            <li>
              Diese Daten dienen dem Zweck der nutzerfreundlichen,
              funktionsfähigen und sicheren Auslieferung unserer Website an Sie
              mit Funktionen und Inhalten sowie deren Optimierung und
              statistischen Auswertung.
            </li>
            <li>
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch
              liegendes berechtigtes Interesse an der Datenverarbeitung nach
              Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
            </li>
            <li>
              Wir speichern aus Sicherheitsgründen diese Daten in
              Server-Logfiles für die Speicherdauer von 70 Tagen. Nach Ablauf
              dieser Frist werden diese automatisch gelöscht, es sei denn wir
              benötigen deren Aufbewahrung zu Beweiszwecken bei Angriffen auf
              die Serverinfrastruktur oder anderen Rechtsverletzungen.
            </li>
          </ol>
        </section>

        <section>
          <h2>Cookies</h2>
          <ol>
            <li>
              Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website.
              Cookies sind kleine Textdateien, die Ihr Internet-Browser auf
              Ihrem Rechner ablegt und speichert. Wenn Sie unsere Website erneut
              aufrufen, geben diese Cookies Informationen ab, um Sie automatisch
              wiederzuerkennen. Zu den Cookies zählen auch die sog.
              „Nutzer-IDs“, wo Angaben der Nutzer mittels pseudonymisierter
              Profile gespeichert werden. Wir informieren Sie dazu beim Aufruf
              unserer Website mittels eines Hinweises auf unsere
              Datenschutzerklärung über die Verwendung von Cookies zu den zuvor
              genannten Zwecken und wie Sie dieser widersprechen bzw. deren
              Speicherung verhindern können („Opt-out“).
            </li>
            <li>
              <strong>Es werden folgende Cookie-Arten unterschieden:</strong>
              <ul>
                <li>
                  <strong>Notwendige, essentielle Cookies:</strong> Essentielle
                  Cookies sind Cookies, die zum Betrieb der Webseite unbedingt
                  erforderlich sind, um bestimmte Funktionen der Webseite wie
                  Logins, Warenkorb oder Nutzereingaben z.B. bzgl. Sprache der
                  Webseite zu speichern.
                </li>
                <li>
                  <strong>Session-Cookies:</strong> Session-Cookies werden zum
                  Wiedererkennen mehrfacher Nutzung eines Angebots durch
                  denselben Nutzer (z.B. wenn Sie sich eingeloggt haben zur
                  Feststellung Ihres Login-Status) benötigt. Wenn Sie unsere
                  Seite erneut aufrufen, geben diese Cookies Informationen ab,
                  um Sie automatisch wiederzuerkennen. Die so erlangten
                  Informationen dienen dazu, unsere Angebote zu optimieren und
                  Ihnen einen leichteren Zugang auf unsere Seite zu ermöglichen.
                  Wenn Sie den Browser schließen oder Sie sich ausloggen, werden
                  die Session-Cookies gelöscht.
                </li>
                <li>
                  <strong>Persistente Cookies:</strong> Diese Cookies bleiben
                  auch nach dem Schließen des Browsers gespeichert. Sie dienen
                  zur Speicherung des Logins, der Reichweitenmessung und zu
                  Marketingzwecken. Diese werden automatisiert nach einer
                  vorgegebenen Dauer gelöscht, die sich je nach Cookie
                  unterscheiden kann. In den Sicherheitseinstellungen Ihres
                  Browsers können Sie die Cookies jederzeit löschen.
                </li>
                <li>
                  <strong>
                    Cookies von Drittanbietern (Third-Party-Cookies insb. von
                    Werbetreibenden):
                  </strong>
                  Entsprechend Ihren Wünschen können Sie Ihre
                  Browser-Einstellung konfigurieren und z. B. Die Annahme von
                  Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen
                  Sie jedoch an dieser Stelle darauf hin, dass Sie dann
                  eventuell nicht alle Funktionen dieser Website nutzen können.
                  Lesen Sie Näheres zu diesen Cookies bei den jeweiligen
                  Datenschutzerklärungen zu den Drittanbietern.
                </li>
              </ul>
            </li>
            <li>
              <strong>Datenkategorien:</strong> Nutzerdaten, Cookie, Nutzer-ID
              (inb. die besuchten Seiten, Geräteinformationen, Zugriffszeiten
              und IP-Adressen).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Die so erlangten
              Informationen dienen dem Zweck, unsere Webangebote technisch und
              wirtschaftlich zu optimieren und Ihnen einen leichteren und
              sicheren Zugang auf unsere Website zu ermöglichen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Wenn wir Ihre personenbezogenen
              Daten mit Hilfe von Cookies aufgrund Ihrer Einwilligung
              verarbeiten („Opt-in“), dann ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO
              die Rechtsgrundlage. Ansonsten haben wir ein berechtigtes
              Interesse an der effektiven Funktionalität, Verbesserung und
              wirtschaftlichen Betrieb der Website, so dass in dem Falle Art. 6
              Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage ist. Rechtsgrundlage
              ist zudem Art. 6 Abs. 1 S. 1 lit. b) DS-GVO, wenn die Cookies zur
              Vertragsanbahnung z.B. bei Bestellungen gesetzt werden.
            </li>
            <li>
              <strong>Speicherdauer/ Löschung:</strong> Die Daten werden
              gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
              nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur
              Bereitstellung der Website ist dies der Fall, wenn die jeweilige
              Session beendet ist. Cookies werden ansonsten auf Ihrem Computer
              gespeichert und von diesem an unsere Seite übermittelt. Daher
              haben Sie als Nutzer auch die volle Kontrolle über die Verwendung
              von Cookies. Durch eine Änderung der Einstellungen in Ihrem
              Internetbrowser können Sie die Übertragung von Cookies
              deaktivieren oder einschränken. Bereits gespeicherte Cookies
              können jederzeit gelöscht werden. Dies kann auch automatisiert
              erfolgen. Werden Cookies für unsere Website deaktiviert, können
              möglicherweise nicht mehr alle Funktionen der Website
              vollumfänglich genutzt werden.
            </li>
            <li>
              <strong>
                Hier finden Sie Informationen zur Löschung von Cookies nach
                Browsern:
              </strong>
              <ul>
                <li>
                  <strong>Chrome:</strong>{" "}
                  <a
                    href="https://support.google.com/chrome/answer/95647"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647
                  </a>
                </li>
                <li>
                  <strong>Safari:</strong>{" "}
                  <a
                    href="https://support.apple.com/de-at/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    https://support.apple.com/de-at/guide/safari/sfri11471/mac
                  </a>
                </li>
                <li>
                  <strong>Firefox:</strong>{" "}
                  <a
                    href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen
                  </a>
                </li>
                <li>
                  <strong>Internet Explorer:</strong>{" "}
                  <a
                    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies
                  </a>
                </li>
                <li>
                  <strong>Microsoft Edge:</strong>{" "}
                  <a
                    href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <strong>Widerspruch und „Opt-Out“:</strong> Das Speichern von
              Cookies auf Ihrer Festplatte können Sie unabhängig von einer
              Einwilligung oder gesetzlichen Erlaubnis allgemein verhindern,
              indem Sie in Ihren Browser-Einstellungen „keine Cookies
              akzeptieren“ wählen. Dies kann aber eine Funktionseinschränkung
              unserer Angebote zur Folge haben. Sie können dem Einsatz von
              Cookies von Drittanbietern zu Werbezwecken über ein sog. „Opt-out“
              über diese amerikanische Website{" "}
              <a
                href="https://optout.aboutads.info"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://optout.aboutads.info
              </a>{" "}
              oder diese europäische Website{" "}
              <a
                href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                target="_blank"
                rel="nofollow noreferrer"
              >
                http://www.youronlinechoices.com/de/praferenzmanagement/
              </a>{" "}
              widersprechen.
            </li>
          </ol>
        </section>

        <section>
          <h2>Abwicklung von Verträgen</h2>
          <ol>
            <li>
              Wir verarbeiten Bestandsdaten (z.B. Unternehmen,
              Titel/akademischer Grad, Namen und Adressen sowie Kontaktdaten von
              Nutzern, E-Mail), Vertragsdaten (z.B. in Anspruch genommene
              Leistungen, Namen von Kontaktpersonen) und Zahlungsdaten (z.B.
              Bankverbindung, Zahlungshistorie) zwecks Erfüllung unserer
              vertraglichen Verpflichtungen (Kenntnis, wer Vertragspartner ist;
              Begründung, inhaltliche Ausgestaltung und Abwicklung des Vertrags;
              Überprüfung auf Plausibilität der Daten) und Serviceleistungen
              (z.B. Kontaktaufnahme des Kundenservice) gem. Art. 6 Abs. 1 S. 1
              lit b) DS-GVO. Die in Onlineformularen als verpflichtend
              gekennzeichneten Eingaben, sind für den Vertragsschluss
              erforderlich.
            </li>
            <li>
              Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich
              nicht, außer sie ist zur Verfolgung unserer Ansprüche (z.B.
              Übergabe an Rechtsanwalt zum Inkasso) oder zur Erfüllung des
              Vertrags (z.B. Übergabe der Daten an Zahlungsanbieter)
              erforderlich oder es besteht hierzu besteht eine gesetzliche
              Verpflichtung gem. Art. 6 Abs. 1 S. 1 lit. c) DS-GVO.
            </li>
            <li>
              Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um
              Sie über weitere interessante Produkte aus unserem Portfolio zu
              informieren oder Ihnen E-Mails mit technischen Informationen
              zukommen lassen.
            </li>
            <li>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist für
              die Bestands- und Vertragsdaten dann der Fall, wenn die Daten für
              die Durchführung des Vertrages nicht mehr erforderlich sind und
              keine Ansprüche mehr aus dem Vertrag geltend gemacht werden
              können, weil diese verjährt sind (Gewährleistung: zwei Jahre /
              Regelverjährung: drei Jahre). Wir sind aufgrund handels- und
              steuerrechtlicher Vorgaben verpflichtet, Ihre Adress-, Zahlungs-
              und Bestelldaten für die Dauer von zehn Jahren zu speichern.
              Allerdings nehmen wir bei Vertragsbeendigung nach drei Jahren eine
              Einschränkung der Verarbeitung vor, d. h. Ihre Daten werden nur
              zur Einhaltung der gesetzlichen Verpflichtungen eingesetzt.
              Angaben im Nutzerkonto verbleiben bis zu dessen Löschung.
            </li>
          </ol>
        </section>

        <section>
          <h2>Google Analytics</h2>
          <ol>
            <li>
              Wir haben das Webseitenanalyse-Tool „Google Analytics“ (
              <strong>Dienstanbieter:</strong> Google Ireland Limited,
              Registernr.: 368047, Gordon House, Barrow Street, Dublin 4,
              Irland) auf unserer Website integriert. Es besteht hinsichtlich
              der Verwendung der Daten eine gemeinsame Verantwortung
              hinsichtlich der Datenverarbeitung zwischen Google und uns gem.
              Art. 26 DSGVO. Wir haben mit Google vereinbart, dass die primäre
              Verantwortung gemäß DSGVO für die Verarbeitung der Daten wir
              übernehmen und sämtliche Pflichten aus der DSGVO im Hinblick auf
              die Verarbeitung der Daten erfüllen (u. a. Art. 12, 13 DSGVO, Art.
              15 bis 22 DSGVO und Art. 32 bis 34 DSGVO).
            </li>
            <li>
              <strong>
                Datenkategorien und Beschreibung der Datenverarbeitung:
              </strong>{" "}
              User-ID, IP-Adresse (anonymisiert). Beim Besuch unserer Website
              setzt Google einen Cookie auf Ihren Computer, um die Benutzung
              unserer Website durch Sie analysieren zu können. Wir haben die
              IP-Anonymisierung „anonymizeIP“ aktiviert, wodurch die IP-Adressen
              nur gekürzt weiterverarbeitet werden. Auf dieser Webseite wird
              Ihre IP-Adresse von Google daher innerhalb von Mitgliedstaaten der
              Europäischen Union oder in anderen Vertragsstaaten des Abkommens
              über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Webseite wird Google diese Informationen
              benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports
              über die Webseitenaktivitäten zusammenzustellen und um weitere,
              mit der Websitenutzung und der Internetnutzung verbundene,
              Dienstleistungen gegenüber dem Verantwortlichen zu erbringen. Wir
              haben darüber hinaus die geräteübergreifende Analyse von
              Website-Besuchern aktiviert, die über eine sog. User-ID
              durchgeführt wird. Die im Rahmen von Google Analytics von Ihrem
              Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
              Google zusammengeführt. Weitere Informationen zu Datennutzung bei
              Google Analytics finden Sie hier:{" "}
              <a
                href="https://www.google.com/analytics/terms/de.html"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://www.google.com/analytics/terms/de.html
              </a>{" "}
              (Nutzungsbedingungen von Analytics),{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>{" "}
              (Hinweise zum Datenschutz bei Analytics) und Googles
              Datenschutzerklärung{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              .
            </li>
            <li>
              <strong>Zweck der Verarbeitung:</strong> Die Nutzung von Google
              Analytics dient dem Zweck der Analyse, Optimierung und
              Verbesserung unserer Website.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Haben Sie für Verarbeitung
              Ihrer personenbezogenen Daten mittels „Google Analytics“ vom
              Drittanbieter Ihre Einwilligung erteilt („Opt-in“), dann ist Art.
              6 Abs. 1 S. 1 lit. a) DS-GVO die Rechtsgrundlage. Rechtsgrundlage
              ist zudem unser in den obigen Zwecken liegendes berechtigtes
              Interesse (der Analyse, Optimierung und Verbesserung unserer
              Website) an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f)
              DS-GVO. Bei Services, die im Zusammenhang mit einem Vertrag
              erbracht werden, erfolgt das Tracking und die Analyse des
              Nutzerhaltens nach Art. 6 Abs. 1 S. 1 lit. b) DS-GVO, um mit den
              dadurch gewonnen Informationen, optimierte Services zur Erfüllung
              des Vertragszwecks anbieten zu können.
            </li>
            <li>
              <strong>Speicherdauer:</strong> Die von uns gesendeten und mit
              Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs
              verknüpften Daten werden nach Monaten automatisch gelöscht. Die
              Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt
              automatisch einmal im Monat.
            </li>
            <li>
              <strong>Datenübermittlung/Empfängerkategorie:</strong> Google,
              Irland und USA. Wir haben zudem mit Google eine Vereinbarung zur
              Auftragsverarbeitung nach Art. 28 DS-GVO geschlossen.
            </li>
            <li>
              <strong>
                Widerspruchs- und Beseitigungsmöglichkeiten („Opt-Out“):
              </strong>
              <ul>
                <li>
                  Das Speichern von Cookies auf Ihrer Festplatte können Sie
                  allgemein verhindern, indem Sie in Ihren Browser-Einstellungen
                  „keine Cookies akzeptieren“ wählen. Dies kann aber eine
                  Funktionseinschränkung unserer Angebote zur Folge haben. Sie
                  können darüber hinaus die Erfassung der, durch das Cookie
                  erzeugten und auf Ihre Nutzung der Website bezogenen, Daten an
                  Google sowie die Verarbeitung dieser Daten durch Google
                  verhindern, indem sie das unter dem folgenden Link verfügbare
                  Browser-Plugin herunterladen und installieren:{" "}
                  <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    http://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                </li>
                {/*

                TODO: UPDATE WHEN GOOGLE ANALYTICS IS RUNNING
                <li>
                  Als Alternative zum obigen Browser-Plugin können Sie die
                  Erfassung durch Google Analytics unterbinden, indem Sie{" "}
                  <strong>
                    [__hier bitte__den Analytics Opt-Out Link Ihrer Webseite
                    einfügen]
                  </strong>{" "}
                  klicken. Durch den Klick wird ein „Opt-out“-Cookie gesetzt,
                  das die Erfassung Ihrer Daten beim Besuch dieser Webseite
                  zukünftig verhindert. Dieses Cookie gilt nur für unsere
                  Webseite und Ihren aktuellen Browser und hat nur solange
                  Bestand bis Sie Ihre Cookies löschen. In dem Falle müssten Sie
                  das Cookie erneut setzen.
                </li>
                */}
                <li>
                  Die <strong>geräteübergreifende Nutzeranalyse</strong> können
                  Sie in Ihrem Google-Account unter „Meine Daten > persönliche
                  Daten“ deaktivieren.
                </li>
              </ul>
            </li>
          </ol>
        </section>

        <section>
          <h2>Nutzung von WhatsApp Business</h2>

          <p>
            Auf unserer Website bieten wir Ihnen die Möglichkeit, über einen
            WhatsApp-Button direkt mit uns in Kontakt zu treten. Hierbei nutzen
            wir den Dienst WhatsApp Business, der von der WhatsApp Inc., 1601
            Willow Road, Menlo Park, California 94025, USA, betrieben wird.
            Bitte beachten Sie, dass durch die Nutzung des WhatsApp-Buttons Ihre
            personenbezogenen Daten (wie z.B. Ihre Telefonnummer) an WhatsApp
            Inc. weitergeleitet und auf deren Servern in den USA gespeichert
            werden. Diese Daten unterliegen den Datenschutzbestimmungen von
            WhatsApp, die unter WhatsApp Privacy Policy einsehbar sind.
          </p>

          <p>
            Wir weisen darauf hin, dass die Nutzung von WhatsApp Business auf
            Ihrer freiwilligen Entscheidung beruht. Sollten Sie mit dieser Form
            der Kommunikation nicht einverstanden sein, stehen Ihnen alternative
            Kontaktmöglichkeiten zur Verfügung, die keine Weitergabe Ihrer
            personenbezogenen Daten an Drittanbieter erfordern.
          </p>

          <p>
            Durch das Anklicken des WhatsApp-Buttons erklären Sie sich mit der
            Datenübermittlung an WhatsApp einverstanden und bestätigen, dass Sie
            die Datenschutzbestimmungen von WhatsApp gelesen und verstanden
            haben.
          </p>
        </section>

        <section>
          <h2>Google Maps</h2>
          <ol>
            <li>
              Wir haben auf unserer Website Karten von „Google Maps“ (
              <strong>Anbieter</strong>: Google Ireland Limited, Registernr.:
              368047, Gordon House, Barrow Street, Dublin 4, Irland) integriert.
            </li>
            <li>
              <strong>
                Datenkategorie und Beschreibung der Datenverarbeitung:
              </strong>{" "}
              Nutzungsdaten (z.B. IP, Standort, aufgerufene Seite). Mit Google
              Maps können wir den Standort von Adressen und eine
              Anfahrtsbeschreibung direkt auf unserer Website in interaktiven
              Karten anzeigen und Ihnen die Nutzung dieses Tools ermöglichen.
              Bei dem Abruf unserer Website, wo Google Maps integriert ist, wird
              eine Verbindung zu den Servern von Google in den USA aufgebaut.
              Hierbei können Ihre IP und Standort an Google übertragen werden.
              Zudem erhält Google die Information, dass Sie die entsprechende
              Seite aufgerufen haben. Dies erfolgt auch ohne Nutzerkonto bei
              Google. Sollten Sie in Ihren Google-Account eingeloggt sein, kann
              Google die obigen Daten Ihrem Account zuordnen. Wenn Sie dies
              nicht wünschen, müssen Sie sich bei Ihrem Google-Account
              ausloggen. Google erstellt aus solchen Daten Nutzerprofile und
              nutzt diese Daten zum Zwecke der Werbung, Marktforschung oder
              Optimierung seiner Websites.
            </li>
            <li>
              <strong>Zweck der Verarbeitung:</strong> Bereitstellung einer
              nutzerfreundlichen, wirtschaftlichen und optimierten Webseite.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Haben Sie für Verarbeitung
              Ihrer personenbezogenen Daten mittels „Google Maps“ vom
              Drittanbieter Ihre Einwilligung erteilt („Opt-in“), dann ist Art.
              6 Abs. 1 S. 1 lit. a) DS-GVO die Rechtsgrundlage. Rechtsgrundlage
              ist zudem unser in den obigen Zwecken liegendes berechtigtes
              Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f)
              DS-GVO.
            </li>
            <li>
              <strong>Datenübermittlung/Empfängerkategorie:</strong>{" "}
              Drittanbieter in den USA.
            </li>
            <li>
              <strong>Speicherdauer:</strong> Cookies bis zu 6 Monate oder bis
              zur Löschung durch Sie. Ansonsten sobald sie nicht mehr für die
              Verarbeitungszwecke benötigt werden.
            </li>
            <li>
              <strong>Widerspruchs- und Beseitigungsmöglichkeit:</strong> Sie
              haben gegenüber Google ein Widerspruchsrecht gegen die Bildung von
              Nutzerprofilen. Bitte richten Sie sich deswegen direkt an Google
              über die unten genannte Datenschutzerklärung. Ein
              Opt-Out-Widerspruch hinsichtlich der Werbe-Cookies können Sie hier
              in Ihrem Google-Account vornehmen:{" "}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
            <li>
              In den Nutzungsbedingungen von Google Maps unter{" "}
              <a
                href="https://www.google.com/intl/de_de/help/terms_maps.html"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://www.google.com/intl/de_de/help/terms_maps.html
              </a>{" "}
              und in der Datenschutzerklärung für Werbung von Google unter{" "}
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://policies.google.com/technologies/ads
              </a>{" "}
              finden Sie weitere Informationen zur Verwendung von Google Cookies
              und deren Werbetechnologien, Speicherdauer, Anonymisierung,
              Standortdaten, Funktionsweise und Ihre Rechte. Allgemeine
              Datenschutzerklärung von Google:{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              .
            </li>
          </ol>
        </section>

        <section>
          <h2>Rechte der betroffenen Person</h2>
          <ol>
            <li>
              <strong>
                Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
              </strong>
              <br />
              <br />
              Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1
              S. 1 lit. a), Art. 7 DS-GVO beruht, haben Sie das Recht, die
              Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
              aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
              wird dadurch nicht berührt.
              <br />
              <br />
              Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
              Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO
              stützen, können Sie Widerspruch gegen die Verarbeitung einlegen.
              Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur
              Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns
              jeweils bei der nachfolgenden Beschreibung der Funktionen
              dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten
              wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen
              Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle
              Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden
              entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen
              unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer
              wir die Verarbeitung fortführen.
              <br />
              <br />
              Sie können der Verarbeitung Ihrer personenbezogenen Daten für
              Zwecke der Werbung und Datenanalyse jederzeit widersprechen. Das
              Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren
              Werbewiderspruch können Sie uns unter folgenden Kontaktdaten
              informieren:
              <br />
              <br />
              WTM Sportwagen Werkstatt
              <br />
              Grünberger Str. 140
              <br />
              35394
              <br />
              Inhaber Andreas Wutschke
              <br />
              E-Mail-Adresse: werkstatt@wtm-sportwagen.com
              <br />
            </li>
            <li>
              <strong>Recht auf Auskunft</strong>
              <br />
              Sie haben ein Recht auf Auskunft über Ihre bei uns gespeicherten
              persönlichen Daten nach Art. 15 DS-GVO. Dies beinhaltet
              insbesondere die Auskunft über die Verarbeitungszwecke, die
              Kategorie der personenbezogenen Daten, die Kategorien von
              Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder
              werden, die geplante Speicherdauer, die Herkunft ihrer Daten,
              sofern diese nicht direkt bei Ihnen erhoben wurden.
              <br />
              <br />
            </li>
            <li>
              <strong>Recht auf Berichtigung</strong>
              <br />
              Sie haben ein Recht auf Berichtigung unrichtiger oder auf
              Vervollständigung richtiger Daten nach Art. 16 DS-GVO.
            </li>
            <li>
              <strong>Recht auf Löschung</strong>
              <br />
              Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten
              nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen
              Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw. Rechte
              zur weiteren Speicherung stehen dieser entgegen.
            </li>
            <li>
              <strong>Recht auf Einschränkung</strong>
              <br />
              Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, wenn eine der
              Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt
              ist:
              <ul>
                <li>
                  Wenn Sie die Richtigkeit der Sie betreffenden
                  personenbezogenen für eine Dauer bestreiten, die es dem
                  Verantwortlichen ermöglicht, die Richtigkeit der
                  personenbezogenen Daten zu überprüfen;
                </li>
                <li>
                  die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                  personenbezogenen Daten ablehnen und stattdessen die
                  Einschränkung der Nutzung der personenbezogenen Daten
                  verlangen;
                </li>
                <li>
                  der Verantwortliche die personenbezogenen Daten für die Zwecke
                  der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen benötigen, oder
                </li>
                <li>
                  wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs.
                  1 DS-GVO eingelegt haben und noch nicht feststeht, ob die
                  berechtigten Gründe des Verantwortlichen gegenüber Ihren
                  Gründen überwiegen.
                </li>
              </ul>
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit</strong>
              <br />
              Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO,
              was bedeutet, dass Sie die bei uns über Sie gespeicherten
              personenbezogenen Daten in einem strukturierten, gängigen und
              maschinenlesbaren Format erhalten können oder die Übermittlung an
              einen anderen Verantwortlichen verlangen können.
            </li>
            <li>
              <strong>Recht auf Beschwerde</strong>
              <br />
              Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In
              der Regel können Sie sich hierfür an die Aufsichtsbehörde
              insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
              Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes wenden.
            </li>
          </ol>
        </section>

        <section>
          <h2>Datensicherheit</h2>
          <p>
            Um alle personenbezogen Daten, die an uns übermittelt werden, zu
            schützen und um sicherzustellen, dass die Datenschutzvorschriften
            von uns, aber auch unseren externen Dienstleistern eingehalten
            werden, haben wir geeignete technische und organisatorische
            Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle
            Daten zwischen Ihrem Browser und unserem Server über eine sichere
            SSL-Verbindung verschlüsselt übertragen.
          </p>
        </section>

        <section>
          <h2>Stand: 14.08.2024</h2>
          <p>
            Quelle:{" "}
            <a
              href="https://www.juraforum.de"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Mehr &gt;&gt;
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
