import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Projects.css";

export function Projects() {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
  };

  const gulf = () => {
    return (
      <div className="project-section">
        <div
          className="container p-20 description"
          style={{ background: "#97D1EE", padding: "100px 60px 60px 60px" }}
        >
          <h1 className="headline-blue">Unser Meisterstück - UNIKAT 1</h1>
          <h2>Aktuelles Projekt</h2>
          <p>
            Das Basismodell war ein <strong>911 SC 3.0 Targa aus 1982</strong>.
            Der Motor wurde von Thomas umfangreich revidiert und zusätzlich
            wurde liebevoll die Leistung optimiert. Das Getriebe wurde ebenfalls
            komplett überholt und schaltet perfekt. Er bekam eine 964er
            Nockenwelle und die 3,2er Kolben und Zylinder, dazu passend eine 996
            Turbobremsanlage. Der Gulf vermittelt Fahrspaß pur. Inspiriert von
            dem legendärem Gulf-Rennwagen haben wir auch die Karosserie, innen
            wie außen einschließlich einer spektakulären Starterkulisse, dem
            Thema entsprechend kompromisslos umgesetzt. Dass Ergebnis kann sich
            sehen lassen - <strong>Classic Data Zustand 1</strong> - alle
            Karosserieänderungen eingetragen. Dieses Fahrzeug, gibt es in dieser
            Konfiguration nur einmal auf der Welt.
          </p>
        </div>
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img
                src="../serie9-gallery/1.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/2.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/3.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/4.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/5.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/6.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/7.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/8.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/9.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/6b.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../serie9-gallery/7b.png"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
          </Slider>
        </div>
      </div>
    );
  };

  const oelklappe = () => {
    return (
      <div className="project-section">
        <div className="slider-container change-order">
          <Slider {...settings}>
            <div>
              <img
                src="../oelklappe/5.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/2.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/3.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/4.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/1.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/6.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
            <div>
              <img
                src="../oelklappe/7.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
          </Slider>
        </div>
        <div
          className="container p-20 description"
          style={{
            padding: "100px 60px 20px 60px",
            color: "black",
            backgroundColor: "white",
          }}
        >
          <h1 className="headline-blue">Laufendes Projekt - Unikat 2</h1>
          <h2>Aktuelles Projekt</h2>
          <p>
            <strong>Das Basismodell ist ein 911 T Coupe Ölklappe 1972. </strong>
            Der Motor und das Getriebe befinden sich gerade in der Revision. Als
            nächster Schritt erfolgt die Abrüstung der Karosserie. Danach wird
            der Karosseriebauer die Lackierung vorbereiten. Bevor wir unseren
            Ledermann mit der gewünschten Ausstattung beauftragen, müssen wir
            noch die finale Entscheidung treffen, was für ein UNIKAT 2 es werden
            soll. - Nahe am Orginal mit perfekten Optimierungen ? - Ölklappe
            Ralley Dakar ? - Martini Porsche ? Die Diskussionen sind am Laufen
            und wir lassen uns überraschen.
          </p>
        </div>
      </div>
    );
  };

  const sportclassic = () => {
    return (
      <div className="project-section">
        <div
          className="container p-20 description"
          style={{ background: "black", padding: "100px 60px 60px 60px" }}
        >
          <h1 className="headline-blue">997 Carrera 4 GTS Coupe</h1>
          <h2>Aktuelles Projekt</h2>
          <p>
            Hier wurde die Heckschürze mit Bürzel und Doppelrohr Auspuff - ohne
            Klappe dem Sport Classic Heck perfekt nachempfunden. Die
            Frontschürze haben wir nur um die passende Frontlippe ergänzt.
            Abschließend bekam er noch die passenden Streifen in matter Folie.
            Hier laufen gerade interne und intensive Diskussionen, was wir aus
            den 408 PS machen können und wollen.
          </p>
        </div>
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img
                src="../sportclassic/sportclassic-1.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>

            <div>
              <img
                src="../sportclassic/sportclassic-3.jpg"
                alt="Serie9 Gallery 1"
                className="gallery-image"
              />
            </div>
          </Slider>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{gulf()}</div>
      <div>{oelklappe()}</div>
      <div>{sportclassic()}</div>
    </div>
  );
}
