import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Projects.css";

export function WorkshopGallery() {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
  };

  return (
    <div style={{ marginBottom: -5 }}>
      <Slider {...settings}>
        <div>
          <img
            src="../workshop/werkstatt-2.jpg"
            alt="Serie9 Gallery 1"
            className="gallery-image"
          />
        </div>
        <div>
          <img
            src="../workshop/werkstatt-3.jpg"
            alt="Serie9 Gallery 2"
            className="gallery-image"
          />
        </div>
        <div>
          <img
            src="../workshop/werkstatt-4.jpg"
            alt="Serie9 Gallery 3"
            className="gallery-image"
          />
        </div>

        <div>
          <img
            src="../workshop/werkstatt-8.jpg"
            alt="Serie9 Gallery 3"
            className="gallery-image"
          />
        </div>
        <div>
          <img
            src="../workshop/werkstatt-6.jpg"
            alt="Serie9 Gallery 3"
            className="gallery-image"
          />
        </div>
      </Slider>
    </div>
  );
}
