import React, { useState } from "react";
import "./Maps.css";
import {
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";

const customStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      { visibility: "on" },
      { weight: 8 },
      { lightness: 25 },
      { color: "#bdbdbd" },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 50.586216,
  lng: 8.707721,
};
const customMarkerIcon = {
  path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  fillColor: "#97D1EE",
  fillOpacity: 1,
  strokeWeight: 0,
  scale: 2,
};

const Maps = () => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [directions, setDirections] = useState(null);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");

  const handleOpenGoogleMaps = () => {
    console.log("drr");

    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      "Grünberger Straße 140, 35394 Gießen"
    )}`;
    window.open(url, "_blank");
  };

  const handleMarkerClick = () => {
    setInfoWindowOpen(true);
    setInfoWindowPosition(center);
  };

  const handleDirectionsResponse = (response) => {
    if (response.status === "OK") {
      setDirections(response);
    } else {
      console.error("Error fetching directions", response);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (origin && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        handleDirectionsResponse
      );
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          borderBottom: "2px solid white",
          flexWrap: "wrap",
        }}
      >
        <div className="opening-hours">
          <div style={{ marginTop: 20 }}>
            <h2>Anfahrt</h2>
            <p>WTM Sportwagen Werkstatt</p>
            <p>Grünberger Straße 140, 35394 Gießen</p>
          </div>
          <div style={{ marginTop: 40, marginBottom: 40 }}>
            <h2>Öffnungszeiten</h2>
            <p>Mon. - Fr 08:00 - 17:00 Uhr</p>
            <p>Samstag 08:00 - 12:00 Uhr</p>
            <p>Und nach Vereinbarung</p>
            <button
              type="button"
              onClick={handleOpenGoogleMaps}
              style={{ margin: "40px auto", textAlign: "center" }}
              className="btn-anfahrt"
            >
              Hier direkt Anfahrt berechnen!
            </button>
          </div>
        </div>

        <div className="map">
          <LoadScript
            googleMapsApiKey="AIzaSyAAajM6qAFEx2T4stNcbQpZgE9MaNWms14"
            onError={() => console.error("Error loading Google Maps script")}
            onLoad={() => console.log("Google Maps script loaded")}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={9}
              options={{
                styles: customStyle,
                streetViewControl: false,
                mapTypeControl: false, // Disable the Street View control
                gestureHandling: "none",
              }}
            >
              <MarkerF title="WTM Sportwagen" position={center} />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default Maps;
