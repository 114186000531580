import React from "react";
import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import TelephoneEmailLink from "./TelephoneEmailLink";
import "./ContactForm.css";
import WhatsAppButton from "./WhatsAppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label className="checkbox-input">
        <input {...field} {...props} type="checkbox" />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const ContactForm = () => {
  return (
    <div className="form-outer">
      <div className="form-inner">
        <div className="form-inner-inner">
          <div className="form-wrapper">
            <h2>Termin vereinbaren</h2>

            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
                consent: false, // Added initial value for the checkbox
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .max(25, "Must be 25 characters or less")
                  .required("Pflichtfeld"),
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Pflichtfeld"),
                phone: Yup.string().required("Pflichtfeld"),
                message: Yup.string().required("Pflichtfeld"),
                consent: Yup.boolean()
                  .oneOf([true], "Sie müssen zustimmen") // Validation for the checkbox
                  .required("Pflichtfeld"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                emailjs
                  .send(
                    "service_t0w7lhk",
                    "template_nbeag76",
                    {
                      from_name: values.name,
                      email: values.email,
                      message: values.message,
                      phone: values.phone,
                    },
                    "7yrBfJRP1uVs_QHMZ"
                  )
                  .then(
                    (result) => {
                      toast.success(
                        "Vielen Dank für Ihre Nachricht! Wir melden uns unverzüglich bei Ihnen."
                      );
                      resetForm();
                    },
                    (error) => {
                      toast.error(
                        "Ein Fehler ist aufgetreten, bitte kontaktieren Sie uns direkt per Telefon oder Email."
                      );
                    }
                  )
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <MyTextInput
                    label="Vollständiger Name"
                    name="name"
                    type="text"
                    placeholder="Andre Mustermann"
                  />

                  <MyTextInput
                    label="Ihre Email Addresse"
                    name="email"
                    type="email"
                    placeholder="andre@mustermann.com"
                  />
                  <MyTextInput
                    label="Telefonnumer für Rückfragen"
                    name="phone"
                    type="text"
                    placeholder="0179 3434 888"
                  />

                  <div>
                    <label htmlFor="message">Ihre Nachricht</label>
                    <Field
                      as="textarea"
                      id="message"
                      name="message"
                      rows="10"
                      cols="50"
                      placeholder="Schreiben Sie Ihre Nachricht hier"
                    />
                  </div>

                  <MyCheckbox name="consent"></MyCheckbox>
                  <p style={{ fontSize: 14 }}>
                    Ich stimme zu, dass meine Angaben aus dem Kontaktformular
                    zur Beantwortung meiner Anfrage erhoben und verarbeitet
                    werden. Die Daten werden nach abgeschlossener Bearbeitung
                    Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre
                    Einwilligung jederzeit für die Zukunft per E-Mail an
                    werkstatt@wtm-sportwagen.com widerrufen.
                  </p>

                  <button type="submit" disabled={isSubmitting || !isValid}>
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Abschicken"
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="contact-wrapper">
            <h2>Kontakt</h2>
            <p style={{ marginTop: 40 }}>
              Kontaktieren Sie uns per E-Mail oder Telefon.
            </p>
            <TelephoneEmailLink
              phoneNumber="+49 157 568 50 714"
              emailAddress="werkstatt@wtm-sportwagen.com"
            />
            <p style={{ marginTop: 40 }}>
              Oder schreiben Sie uns direkt per WhatsApp, einfach den Knopf
              drücken.
            </p>
            <WhatsAppButton phoneNumber={"+4915756850714"} pulsate={true} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
