import CookieConsent from "react-cookie-consent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import "./Main.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone } from "@fortawesome/free-solid-svg-icons";

import Home from "./Home";

import Navbar from "../components/Navbar";
import { Routes, Route, Link } from "react-router-dom";

import { Projects } from "./Projects";
import { ContactForm } from "../components/ContactForm";
import { Footer } from "../components/Footer";
import { Concept } from "../components/Concept";
import { Team } from "../components/Team";
import { useLocation } from "react-router-dom";
import { WorkshopGallery } from "./WorkshopGallery";
import Maps from "../components/Maps";
import PulsatingArrow from "../components/PulsatingArrow";

function Main() {
  const handleContactButton = () => {
    console.log("click");
  };
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]); // Depend

  return (
    <div id="start">
      <Navbar />
      <div className="introduction">
        <div className="container">
          <p>
            <span className="underline">
              KFZ-Meisterwerkstatt für Porsche Old & Youngtimer in Gießen.
            </span>{" "}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PulsatingArrow />
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "black",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            backgroundImage: "url(./background-hero.png)", // replace with actual image path
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div id="intro"></div>
          <Concept />
        </div>
      </div>
      <div id="team"></div>
      <Team />
      <div id="project"></div>
      <Projects />
      <div id="appointment"></div>

      <div
        style={{
          backgroundImage: "url(./background-footer.png)", // replace with actual image path
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <ContactForm />
      </div>
      <div id="location">
        <Maps />
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        enableDeclineButton
        cookieName="userConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{
          color: "#fff",
          background: "#8A2BE2",
          fontSize: "13px",
        }}
        expires={150}
        onAccept={() => {
          localStorage.setItem("user-consent", "accepted");
          console.log("Cookies accepted");
          // Load necessary cookies or scripts here
        }}
        onDecline={() => {
          localStorage.setItem("user-consent", "declined");
          console.log("Cookies declined");
          // Prevent loading of non-essential cookies or scripts here
        }}
      >
        Diese Website verwendet Cookies, um das Benutzererlebnis zu verbessern.{" "}
        <a href="/datenschutz" style={{ color: "white" }}>
          Mehr erfahren
        </a>
      </CookieConsent>
    </div>
  );
}

export default Main;
