import React, { useState, useEffect, useRef } from "react";
import "./ImageGrid.css";

const ImageGrid = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeMoreIndex, setActiveMoreIndex] = useState(null);
  const [isSingleColumn, setIsSingleColumn] = useState(false);

  const toggleHover = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const toggleMore = (index) => {
    console.log("mehr ");
    setActiveMoreIndex(activeMoreIndex === index ? null : index);
  };

  useEffect(() => {
    const checkScreenWidth = () => {
      console.log("drr", window.innerWidth <= 480, window.innerWidth);
      setIsSingleColumn(window.innerWidth <= 480);
      console.log("checkScreenWidth: " + isSingleColumn);
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const images = [
    {
      src: "https://via.placeholder.com/300",
      title: "Service / Wartung",
      description: "Wartungsservice für Ihren Porsche",
      detail_title:
        "Premium Wartungsservice für Ihren Porsche – Ihre Investition in Qualität und Langlebigkeit",
      detail:
        "Ein Porsche steht für erstklassige Ingenieurskunst. Um diese Qualitäten dauerhaft zu sichern, ist eine regelmäßige und professionelle Wartung unverzichtbar. In unserer Werkstatt setzen wir moderne Techniken und Originalteile ein, um den höchsten Ansprüchen gerecht zu werden. Unser Rundum-Service deckt alle wichtigen Wartungsaspekte ab und garantiert, dass Ihr Porsche stets in bestem Zustand bleibt. Dies ist nicht nur essenziell für Ihre Sicherheit und Fahrfreude, sondern auch für den Werterhalt Ihres Fahrzeugs. Mit uns haben Sie einen erfahrenen Partner an Ihrer Seite, der sich auf Porsche spezialisiert hat – für Service und Wartung in Hessen, dem Sie vertrauen können.",
    },
    {
      src: "https://via.placeholder.com/300",
      title: "Motor & Getriebe",
      description: "Professionelle Porsche Motorrevision",
      detail_title:
        "Professionelle Porsche Motorrevision und Motorinstandsetzung – Für maximale Leistung und Zuverlässigkeit",
      detail:
        "Um die volle Leistungsfähigkeit und Langlebigkeit Ihres Fahrzeugs zu gewährleisten, ist eine fachgerechte Motorrevision und Motorinstandsetzung unerlässlich. Wir sind spezialisiert auf die Instandsetzung von Porsche Motoren, unabhängig vom Modell – sei es ein F-Modell, G-Modell, 964, 993, 996 oder 997. Mit tiefem Fachwissen, langjähriger Erfahrung und einer Leidenschaft für Porsche sorgen wir dafür, dass Ihr Motor wieder wie neu läuft. Unsere Werkstatt vereint traditionelles Handwerk mit moderner Technologie, um Ihrem Porsche die bestmögliche Pflege zukommen zu lassen. Wir wissen, dass jedes Modell und jedes Baujahr seine spezifischen Anforderungen hat, weshalb wir jeden Motor mit höchster Präzision und Sorgfalt instand setzen. Besonders bei den Motoren des Porsche 964 ist unser spezialisiertes Know-how gefragt. Vereinbaren Sie noch heute einen Termin und lassen Sie Ihren Porsche von Expertenhand wieder in Bestform bringen.",
    },
    {
      src: "https://via.placeholder.com/300",
      title: "Leistungssteigerungen",
      description: "Wir haben lange Jahre Erfahrung mit luftgekühlten 911",
      detail_title: "Leistungssteigerung Porsche 964/ 993 C2/4",
      detail:
        "Die Leistungssteigerung des Porsche 964 bietet in verschiedenen Stufen beeindruckende Verbesserungen der Motorleistung, die auf spezifischen Modifikationen basieren. In Stufe 1 wird der 3,6-Liter-Motor durch den Einsatz von Sport-Nockenwellen, überarbeiteten Zylinderköpfen und optimierten Ein- und Auslasskanälen auf 300 PS gebracht. Eine modifizierte Auspuffanlage und Anpassungen am Motorsteuergerät tragen ebenfalls zur Leistungssteigerung bei, wobei der Zustand von Kolben und Zylindern entscheidend ist. Stufe 2 erhöht die Leistung auf 330 PS durch eine Hubraumvergrößerung auf 3,8 Liter und den Einsatz von Hochverdichtungs-Kolben sowie größeren Einspritzventilen. Eine Kunststoff-Sauganlage ist hier unerlässlich. Die ultimative Steigerung in Stufe 3 bringt den Motor auf 4,0 Liter und 370 PS, wobei umfassende Änderungen an Kurbelwelle, Ölpumpe, und dem gesamten Ansaugsystem vorgenommen werden. RSR-Nockenwellen, eine Motorsport-Steuerung und eine modifizierte Elektronik sorgen für maximale Performance, die das Potential des 964 vollständig ausschöpfen.",
    },

    {
      src: "https://via.placeholder.com/300",
      title: "Porsche Restauration",
      description: "Ihr Ansprechpartner zum Thema Porsche Restaurierung",
      detail_title: "Unbregrenzte Möglichkeiten",
      detail:
        "Die Restaurierung eines Porsche ist ein anspruchsvolles Unterfangen, das Präzision und Detailgenauigkeit erfordert, um den Zustand des Fahrzeugs wiederherzustellen und gleichzeitig seinen Wert zu erhalten oder zu steigern. Der Prozess beginnt in der Regel mit einer gründlichen Bestandsaufnahme, bei der der Zustand von Karosserie, Fahrwerk, Motor und Interieur überprüft wird. Rost- und Karosserieschäden werden sorgfältig behoben, wobei originale oder originalgetreue Ersatzteile verwendet werden. Der Motor und das Getriebe werden häufig komplett zerlegt, gereinigt und mit neuen oder überholten Teilen wieder zusammengebaut, um die ursprüngliche Leistung und Zuverlässigkeit sicherzustellen. Das Interieur wird ebenfalls komplett restauriert, wobei auf die Verwendung der richtigen Materialien und Farben geachtet wird, je nach Geschmack. Eine hochwertige Lackierung in einer tollen Farbe rundet den Prozess ab und verleiht dem Porsche den Glanz, den er einst hatte. Jede Restaurierung ist ein Balanceakt zwischen der Bewahrung der Authentizität und der Integration moderner Optimierungen, um sowohl den Wert, die Optik als auch die Fahrfreude zu maximieren.",
    },
  ];

  return (
    <div>
      <div className="image-grid">
        {images.map((image, index) => (
          <div className="wrapper">
            <div
              className={`image-container ${
                activeIndex === index || index == 0 ? "hover" : ""
              }`}
              key={index}
              onClick={() => toggleHover(index)}
            >
              {/* <img src={image.src} alt={image.title} className="image" />*/}

              <div className="overlay"></div>
              <div className="title">
                <h3>{image.title}</h3>
                <div className="title-inner">
                  <p>{image.description}</p>
                  <button className="button" onClick={() => toggleMore(index)}>
                    Mehr erfahren
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`more ${
                activeMoreIndex != null &&
                activeMoreIndex === index &&
                isSingleColumn
                  ? "expanded"
                  : ""
              }`}
            >
              <div>
                {activeMoreIndex != null && (
                  <>
                    <h3>{images[activeMoreIndex].detail_title}</h3>
                    <p class="expanded-p">{images[activeMoreIndex].detail}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`more-full ${
          activeMoreIndex != null && !isSingleColumn ? "expanded" : ""
        }`}
      >
        {activeMoreIndex != null && (
          <>
            <h3>{images[activeMoreIndex].detail_title}</h3>
            <p>{images[activeMoreIndex].detail}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageGrid;
