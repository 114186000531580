import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./PulsatingArrow.css"; // Make sure to create this CSS file

const PulsatingArrow = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/#intro");
  };

  return (
    <div className="pulsating-arrow" onClick={handleClick}>
      <FontAwesomeIcon icon={faChevronDown} size="4x" color="#97D1EE" />
    </div>
  );
};

export default PulsatingArrow;
