import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Import the WhatsApp icon from react-icons
import "./WhatsAppButton.css"; // Import the CSS file

const WhatsAppButton = ({ phoneNumber, message, pulsate }) => {
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <div style={styles.crt}>
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
        className={pulsate ? "pulsate" : null} // Apply the pulsate class
      >
        <img src="./whatsapp.png" style={styles.icon} />
      </a>
    </div>
  );
};

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "40px",
    height: "40px",
  },
  crt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default WhatsAppButton;
