import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone } from "@fortawesome/free-solid-svg-icons";

import Home from "./pages/Home";
import About from "./pages/Workshop";
import { Contact } from "./pages/Contact";
import Button from "./components/Button";
import Navbar from "./components/Navbar";
import { Routes, Route, Link } from "react-router-dom";

import { Workshop } from "./pages/Workshop";
import { Projects } from "./pages/Projects";
import { ContactForm } from "./components/ContactForm";
import { Footer } from "./components/Footer";
import { Concept } from "./components/Concept";
import { Team } from "./components/Team";
import Main from "./pages/Main";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Impressum from "./pages/Impressum";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [codeword, setCodeword] = useState("");
  const correctCodeword = "porsche911"; // Replace with your actual codeword

  const handleContactButton = () => {
    console.log("click");
  };

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const handleLogin = () => {
    if (codeword === correctCodeword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect codeword!");
    }
  };

  // if (!isAuthenticated) { // block website for maintenance
  if (false) {
    return (
      <div className="login-container">
        <h2>Diese Website befindet sich im Aufbau.</h2>
        <input
          type="text"
          value={codeword}
          onChange={(e) => setCodeword(e.target.value)}
          placeholder="Passwort"
        />
        <button onClick={handleLogin}>Zugang</button>
      </div>
    );
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/datenschutz" element={<PrivacyPolicy />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </div>
  );
}

export default App;
