import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Footer.css";

export const Footer = () => {
  const footerStyle = {
    color: "white",
    padding: "40px 20px",
  };

  const routes = [
    { path: "/#start", label: "Start" },
    { path: "/#team", label: "Team" },
    { path: "/#workshop", label: "Werkstatt" },
    { path: "/#project", label: "Projekte" },
    { path: "/#appointment", label: "Termine" },
    { path: "/#location", label: "Anfahrt" },
  ];

  return (
    <div style={{ background: "black" }}>
      <footer style={footerStyle}>
        <img
          src="./logo-v2.png"
          alt="Logo"
          className="logo"
          style={{ margin: "20px 0", maxWidth: 140 }}
        />
        <p className="address">Grünberger Straße 140, 35394 Gießen</p>

        <div style={{ marginTop: 20 }}>
          {routes.map((route) => (
            <Link key={route.path} to={route.path} className="footer-link-item">
              {route.label}
            </Link>
          ))}
        </div>
      </footer>
      <div className="terms-section" style={{ backgroundColor: "black" }}>
        <p className="copyright">© 2024 WTM Meisterwerkstatt</p>
        <div className="terms-links">
          <Link
            key="datenschutz"
            to={"/datenschutz"}
            className="footer-link-item"
          >
            Datenschutz­erklärung
          </Link>
          <Link key="impressum" to={"/impressum"} className="footer-link-item">
            Impressum
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
