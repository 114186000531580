import React from "react";
import PropTypes from "prop-types";
import "./TelephoneEmailLink.css";

const TelephoneEmailLink = ({ phoneNumber, emailAddress }) => {
  return (
    <div className="contact-links">
      <a href={`tel:${phoneNumber}`} className="contact-link phone-link">
        {phoneNumber}
      </a>
      <a href={`mailto:${emailAddress}`} className="contact-link email-link">
        {emailAddress}
      </a>
    </div>
  );
};

TelephoneEmailLink.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

export default TelephoneEmailLink;
